import React from "react"
import Layout from "../components/layout/layout"

function PrivacyPolicyPage () {

    return (
        <Layout>
            <section className="grid grid-cols-12 my-32">
                <div className="col-start-3 col-span-8">
                    <h1>Privay Policy</h1>
                    This Privacy Policy describes the commitment of REDBRIDGE and its affiliates to privacy and explains our privacy practices, so that you can make an informed decision about your use of this Site and the information you choose to provide us.<br/><br/>
                    Please read this Privacy Policy carefully so that you understand how we obtain, share and protect the information we have about you.<br/><br/>

                    This Privacy Policy is incorporated and is part of the Terms and Conditions of Use of each of the websites that REDBRIDGE owns and manages, including, but not limited to, the following: www.redbridgeinsurance.com and www.redbridge.cc, denominated collectively and each one separately, as the "Site". All provisions and conditions of the Terms and Conditions of Use apply to this Privacy Policy.<br/><br/>

                    By accessing and using this Site, you agree that you have read and understood the Privacy Policy that is published at the time, and accept and agree to the privacy practices that are described therein.<br/><br/>

                    <h2 className="mt-3">INTRODUCTION</h2>

                    In order to provide you with personalized and relevant information through this Site, REDBRIDGE obtains certain information about you.<br/><br/>
                    REDBRIDGE is fully committed to protecting your privacy.<br/><br/>
                    This Privacy Policy aims to describe our privacy practices with respect to such data.<br/><br/>

                    <h2 className="mt-3">DATA WE GET ABOUT YOU</h2>

                    You, your employer or third-party vendors who provide services on your behalf may give us information about you, such as your name, date of birth, identification number of your medical plan, as well as, in certain circumstances, the name of your health care plan.<br/><br/>
                    Third-party vendors may give us health-related data, such as information about your medical and pharmaceutical claims, about the evaluation of your health risks or other administrative data, so that we can provide you with the services offered by this Site.<br/><br/>
                    We may also request data from you directly through your use of the Site, including, but not limited to, the following: demographic data (such as your name, address, date of birth, email and your IP address (Internet provider) and data related to your health (such as your height, weight, medication history or experience with certain conditions or treatments) or other data.<br/><br/>
                    Like many other sites, the servers used on the Internet to operate this Site may obtain certain data corresponding to you and the equipment and media used to access the Internet and the Site. We may also ask you for certain identification information, or we may obtain it about you in relation to your use of this Site. For example, we may obtain and store the relevant content and data of any correspondence you have exchanged with us or with the  consultants or representatives, regardless of the subject or means of communication through which such correspondence has been exchanged.<br/><br/>

                    <h2 className="mt-3">HOW WE USE THE INFORMATION WE HAVE ABOUT YOU</h2>

                    REDBRIDGE will use the information provided by you on this Site, or that we have obtained in some other way, to provide you with services and to direct the operations of our Company.<br/><br/>
                    For example, we may use your information to notify you of opportunities, products, services or for other business reasons. We may also use the information provided by you to send you information electronically, by mail, by phone or by other means.<br/><br/>
                    REDBRIDGE may also use your information to provide you with services or to make sure that the program (software), computer equipment (hardware) or other systems are functioning properly and safely.REDBRIDGE may combine your information with that of other people who use this Site, and extract all personal identifiers from the resulting combined information, in order to create "unidentified information."<br/><br/> 
                    We use unidentified information to develop products and services that meet the needs of customers, and to analyze and improve the content, features, materials and opportunities that we could make available on the Site.<br/><br/>

                    <h2 className="mt-3">HOW WE SHARE OR DISCLOSE YOUR INFORMATION</h2>

                    We may share your information with your health care providers and your authorized consultant or representative to provide the services of this Site or for other related purposes. Occasionally we may use trusted business partners to provide you with the content available on, or through this Site. Likewise, we may contact you for reasons of the operation of this Site, or to meet your need for our services.<br/><br/>
                    When we use other companies to provide services to us, we require them to protect the confidentiality of all personal information they receive from us or collect by operating on our behalf, and require that such information is only to be used for the purpose of providing the services.<br/><br/>
                    We may need to disclose personally identifiable information, including protected health information, when required by law such as when necessary to comply with a government mandate, judicial proceeding, court order or other legal process.<br/><br/>
                    For example, REDBRIDGE may disclose information about you as necessary to establish or exercise our legal rights; to enforce the Terms and Conditions of Use; to argue legal claims and defend ourselves against them, in relation to any corporate transaction that results in a transfer of assets or line of business related to said data; to protect, secure and amend our data, systems, products or services; and to investigate, prevent or take any other action with respect to real or suspected fraudulent or illegal activities.<br/><br/> 
                    REDBRIDGE may disclose such in information in special cases, such as reaction to a possible threat to the physical safety or well-being of you or others, or to protect property.<br/><br/>

                    <h2 className="mt-3">USE OF COOKIES</h2>

                    Cookies are small files that reside on your computer, technically necessary to store data during a session, and allow us to recognize you as a REDBRIDGE client every time you log in from the same computer and through the same browser.<br/><br/>
                    We use and send “Sessions Cookies” to your computer whenever you log into your REDBRIDGE account to improve navigation on our Site and to regularly collect statistics. Session Cookies are deleted and cease their effect when you end the session or close your browser.<br/><br/>
                    We also use long lasting cookies to display the username on the login panel, so you don’t have to retype your email address every time you log into your REDBRIDGE account. Our cookie files are encoded so that the information stored in them can only be interpreted by the servers on the Site.<br/><br/>
                    Internet browsers and other software allow you to erase cookies from your computer hard drive, block or reject all cookies, or receive a warning before a cookie is stored.  If you choose to reject cookies, you may not have permission to access this Site, or it may not function appropriately or in the manner intended.<br/><br/>

                    <h2 className="mt-3">PROTECTED SITE</h2>

                    We employ commercially reasonable methods to keep information obtained and stored on or through this Site, secure in a password-protected site and encrypted.<br/><br/>
                    Since REDBRIDGE respects your privacy and values your trust, we only grant access to your information to those people who use it to provide services to you, or to conduct other activities described in this Privacy Policy.<br/><br/>
                    REDBRIDGE maintains physical, electronic, and procedural records designed to comply with established standards to help safeguard your information and prevent unauthorized access to such information.<br/><br/>
                    However, no set of security systems are guaranteed to be impenetrable. Therefore, we cannot guarantee or assure that communications sent to, or from this Site, or the data transmitted or stored on, or through the same, remains or will always remain totally protected against a loss, misuse, or  access by unauthorized third parties.<br/><br/>

                    <h2 className="mt-3">YOU HAVE ALTERNATIVES</h2>

                    When exchanging correspondence with REDBRIDGE, with consultants or representatives, or when requesting information or interacting in any other way with REDBRIDGE through this Site, you choose what information to provide, what questions to answer, what comments to make, if you wish to receive more information and how we must proceed to provide you with such information.<br/><br/>
                    Make sure to share only the information which you deem appropriate.<br/><br/>
                    This Site may offer different options for you to accept or reject certain uses and displays specific to certain tools or sections of this Site.  There may also be options for you to designate how you want REDBRIDGE to use or not use your email address for purposes related to the services provided through the Site.  Evaluate these options carefully where available.<br/><br/>
                    Please consider that technologically it is impossible to remove from our servers each and every registered data. If you wish to remove your information from this Site, contact us:<br/><br/>

                    [ricl@redbridge.cc](mailto:ricl@redbridge.cc)<br/><br/>

                    <h2 className="mt-3">INDEPENDENT RESOURCE</h2>

                    If you have any questions or concerns regarding the privacy of your information, contact REDBRIDGE:<br/><br/>

                    [ricl@redbridge.cc](mailto:ricl@redbridge.cc)<br/><br/>

                    REDBRIDGE is committed to maintaining the privacy and security of your information, in compliance with the applicable legal requirements and terms of this Privacy Policy, so it will consider any and all legitimate concerns that your may have.<br/><br/>

                    <h2 className="mt-3">CHANGES TO PRIVACY POLICY</h2>

                    This Privacy Policy is subject to change without notice but any changes will be posted on this page of our Site

                    We may change from time to time this Privacy Policy and our Privacy Practices due to changes made to applicable legal requirements, in our business practices, or in our attempts to provide  better service to meet the needs of  our customers.<br/><br/>
                    When this occurs, we will post the revised version of our Privacy Policy on this Site, followed by the effective or revision date. Any changes will take effect immediately or from the time of publication on this Site. You are responsible for verifying if any changes were made to the Privacy Policy.<br/><br/>

                    <h2 className="mt-3">OTHER WEBSITES</h2>

                    This Site may contain links to other web sites with different privacy policies and practices.<br/><br/>
                    We are not liable or responsible for the privacy policy and practices of any other website.<br/><br/>

                    <h2 className="mt-3">QUESTIONS</h2>

                    If you have any questions related to this Privacy Policy, or you notice a security violation on this Site, or a breach of this Privacy Policy or the Terms and Conditions of Use, please contact REDBRIDGE to:<br/><br/>

                    <p><a href="mailto:info@redbridge.cc" target="_blank" rel="noopener noreferrer">info@redbridge.cc</a></p>

                    <h2 className="mt-3">ACKNOWLEDGEMENT</h2>

                    BY ACCESING OR USING THIS SITE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT OUR PRIVACY POLICY AND PRACTICES, AND OUR USE AND DISCLOSURE OF THE INFORMATION WE HAVE ABOUT YOU, AND AGREE TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO THIS SITE.<br/><br/>

                    <h2 className="mt-3">EFFECTIVE DATE</h2>

                    This Privacy Policy is inforce June 1st, 2015Revision Date May 1st, 2020
                </div>

            </section>
        </Layout>
    )
}

export default PrivacyPolicyPage